import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { Router } from "@reach/router"
import styled from "styled-components"
import tw from "twin.macro"

import Layout from "../components/layout"
// import SEO from "../components/seo";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2b3eb3;
  padding: 2rem 1rem;
  min-height: 100vh;
  ${tw`md:(h-screen)`}
`

const StyledInput = styled.input`
  border: none;
  background-color: white;
`
const StyledLabel = styled.label`
  margin: 0;
`
const Textarea = styled.textarea`
  resize: none;
  border: none;
  background-color: white;
`
const StyledFormfield = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #1d1d1d;
  padding: 1rem;
  flex: 1;
  width: 24rem;
  max-width: 75vw;
  margin-top: 0.5rem;
`
const Button = styled.button`
  width: 24rem;
  max-width: 75vw;
  border: none;
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
`

const ContactPage = () => {
    const initialState = {
      name: "",
      email: "",
      message: "",
    }

    const [state, setState] = useState(initialState)
    const [error, setError] = useState("")

    const handleSubmit = async e => {
      e.preventDefault()
      await fetch(`${process.env.GATSBY_SERVERLESS_BASE}/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(state),
      })
      console.log(state)
    }

    const handleInput = e => {
      const inputName = e.currentTarget.name
      if (inputName == "existingCustomer") {
        setState(prev => ({ ...prev, subject: "" }))
      }
      const value = e.currentTarget.value
      setState(prev => ({ ...prev, [inputName]: value }))
    }
  return (
    <Layout>
      {/* <SEO title="Hello world!" /> */}
      <Flex>
        <StyledForm onSubmit={handleSubmit}>
          <StyledFormfield>
            <StyledLabel htmlFor="name">Name</StyledLabel>
            <StyledInput
              type="text"
              placeholder="Your name"
              name="name"
              onChange={handleInput}
            />
          </StyledFormfield>
          <StyledFormfield>
            <StyledLabel htmlFor="email">Email</StyledLabel>
            <StyledInput
              type="email"
              placeholder="Your email"
              name="email"
              onChange={handleInput}
            />
          </StyledFormfield>
          <StyledFormfield>
            <StyledLabel htmlFor="text">Message</StyledLabel>
            <Textarea
              type="textarea"
              cols="30"
              rows="5"
              placeholder="Say hi"
              name="message"
              onChange={handleInput}
            />
          </StyledFormfield>{" "}
          <Button type="submit">Send</Button>
          <h4
            onClick={() => {
              navigate(-1)
            }}
            style={{ color: "white", cursor: "pointer", paddingLeft: "1rem" }}
          >
            Go back
          </h4>
        </StyledForm>
      </Flex>
    </Layout>
  )
}

export default ContactPage
